<template>

    <div class="wrapper">
        <!-- Modal Component -->
        <CModal :show.sync="darkModal"
                size="lg"
                :no-close-on-backdrop="true"
                :centered="true"
                title="GatewayFirmware管理"
                :color="modalColor">
            <CRow>
                <CCol md="12">
                    <CCard>
                        <CCardHeader>
                            <strong>ファームウェア</strong> 管理
                        </CCardHeader>
                        <CCardBody>
                            <CForm enctype="multipart/form-data">
                                <CInput label="Version" horizontal="{ label: 'col-2', input: 'col-2'}" ref="version"
                                        autocomplete="FirmwareVersion"
                                        :value="modalValue.firmwareVersion"
                                        @input="modalValue.firmwareVersion = $event"
                                        invalid-feedback="バージョンを入力してください。"
                                        :is-valid="firmwareVersion" />

                                <CInput label="メモ" horizontal
                                        autocomplete="memo"
                                        :value="modalValue.memo"
                                        @input="modalValue.memo = $event"/>

                                <CRow form class="form-group">
                                    <CCol tag="label" sm="3" class="col-form-label">
                                        最新バージョン
                                    </CCol>
                                    <CCol sm="9" class="form-inline">
                                        <CInputCheckbox label="" :value="modalValue.isLatest"
                                                        @update:checked="modalValue.isLatest = $event"/>
                                    </CCol>
                                </CRow>
<!--                                <CInputCheckbox-->
<!--                                        v-for="(option, optionIndex) in options"-->
<!--                                        :key="key + option"-->
<!--                                        :label="option"-->
<!--                                        :value="option"-->
<!--                                        :custom="key > 1"-->
<!--                                        :name="`Option 1${key}`"-->
<!--                                        :checked="optionIndex === key"-->
<!--                                        :inline="key % 2 === 1"-->
<!--                                />-->
                                <CInputFile label="ファイル" horizontal custom
                                            @change="modalValue.file = $event[0]"
                                            :placeholder="modalValue.file == null ? 'ファイルを選択してください' : modalValue.file.name"/>


                                <CInput label="更新予定日" horizontal type="date"
                                        :value="modalValue.updateReservDate"
                                        @input="modalValue.updateReservDate = $event"
                                        :is-valid="updateReservDate"/>

                                <CInput label="更新予定時刻" horizontal type="time"
                                        :value="modalValue.updateReservTime"
                                        @input="modalValue.updateReservTime = $event"
                                        :is-valid="updateReservTime"/>

                            </CForm>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
            <template #header>
                <h6 class="modal-title">追加・修正</h6>
                <CButtonClose @click="darkModal = false" class="text-white"/>
            </template>
            <template #footer>
                <CButton @click="darkModal = false" color="danger">キャンセル</CButton>
                <CButton @click="save()" color="success" :disabled="isFormValid">反映</CButton>
            </template>
        </CModal>
        <!--  マッピングModalStart-->
        <CModal :show.sync="mappingModal"
                :size.sync="modalSize"
                :no-close-on-backdrop="true"
                :centered="true"
                title="Gatewayファームウェアマッピング"
                :color="modalColor">

            <CRow>
                <CCol col="12" xl="12">
                    <transition name="slide">
                        <CCard>
                            <CCardHeader>
                                <CRow>
                                    <CCol col="5">
                                        <CInput class="mb-3" placeholder="S/N" @input="keyword = $event">
                                            <template #prepend-content>S/N</template>
                                        </CInput>
                                    </CCol>
                                    <CCol col="5">
                                        <CInput class="mb-3" placeholder="S/N" @input="keyword = $event">
                                            <template #prepend-content>S/N</template>
                                            <template #append>
                                                <CButton color="info" @click="test(1,$event)">検索</CButton>
                                            </template>
                                        </CInput>
                                    </CCol>
                                    <CCol col="2">
                                        <CButton color="primary" v-on:click="allCheck(true)">全選択</CButton>
                                        <CButton color="primary" v-on:click="allCheck(false)">全解除</CButton>
                                    </CCol>
                                </CRow>
                            </CCardHeader>
                            <CCardBody>
                                <CDataTable
                                        hover
                                        striped
                                        :items="items"
                                        :fields="fields"
                                        index-column="true"
                                        clickable-rows
                                        filer-row
                                        sorting
                                        :loading="loading"
                                        table-filter>
                                    <template #cnt="data">
                                        <td class="text-start">
                                            <CButton color="success" @click="goToGatewayList(data)">{{data.item.cnt}}
                                            </CButton>
                                        </td>
                                    </template>
                                </CDataTable>
                            </CCardBody>
                        </CCard>
                    </transition>
                </CCol>
            </CRow>

            <template #header>
                <h6 class="modal-title">マッピング</h6>
                <CButtonClose @click="mappingModal = false" class="text-white"/>
            </template>
            <template #footer>
                <CButton @click="mappingModal = false" color="danger">キャンセル</CButton>
                <CButton @click="save()" color="success" :disabled="isFormValid">反映</CButton>
            </template>
        </CModal>
        <!-- マッピングModalEnd -->
        <!-- テーブルAREA -->
        <CRow>
            <CCol col="12" xl="12">
                <transition name="slide">
                    <CCard>
                        <CCardHeader>
                            <CRow>
                                <CCol col="6"><h4>ファームウェア</h4></CCol>
                                <CCol col="6" class="text-right">
                                    <CButton color="primary" @click="createMappingModal" class="mr-1">マッピング</CButton>
                                    <CButton color="primary" @click="createModal" class="mr-1">追加</CButton>
                                </CCol>
                            </CRow>
                        </CCardHeader>
                        <CCardBody>
                            <CDataTable
                                    @filtered-items-change="filterss"
                                    cleaner
                                    column-filter table-filter hover striped sorter :items="items" :fields="fields" index-column clickable-rows filer-row
                                    sorting :loading="loading" pagination>
                                <template #cnt="data">
                                    <td class="text-start">
                                        <CButton color="success" @click="goToGatewayList(data)">{{data.item.cnt}}
                                        </CButton>
                                    </td>
                                </template>

                                <template #thead-top>
                                        thead-top
                                </template>

                                <template #cleaner>
                                        cleaner
                                </template>

                                <template #over-table>
                                    over-table
                                </template>
                                <template #caption="data">
                                    caption {{data}}
                                </template>

                                <template #under-table>
                                    under-table
                                </template>
                            </CDataTable>
                        </CCardBody>
                    </CCard>
                </transition>
            </CCol>
        </CRow>
    </div>

</template>

<script>

    // import Validator from '@/validate';
    import {setTimeout} from 'timers';

    export default {
        name: 'GatewayFirmware',
        data: () => {
            return {
                items: [],
                loading: false,
                fields: [
                    {key: 'firmwareVersion', label: 'Version'},
                    //{ key: 'eid', label: 'eid' },
                    {key: 'memo', label: 'メモ'},
                    {key: 'fileName', label: 'ファイル名'},
                    {key: "updateReservTime", label: '更新予約時間'},
                    {key: 'created', label: '登録日時'},
                    {key: 'updated', label: '更新日時'},
                    {key: 'cnt', label: 'IoTゲートウェイ数'},
                ],
                perPage: 10,
                darkModal: false,
                mappingModal: false,
                modalColor: 'warning',
                modalValue: {file:null},
                paging: {
                    currentPage: 1,
                    totalPages: 1
                }
                , isFormValid: true
                , selectedFile: null
            }
        },
        mounted() {
            this.get();
        }, methods: {
            filterss:function(e){
                console.log("filterss",e);
            },
            files:function(e){
                console.log(e)
            },
            showHeaderToggle: function () {
                console.log("this.$store.state.showHeader", this.$store.state.showHeader);
                this.$store.commit("toggleHeader");
            },
            test: function (e) {
                console.log(e)
            }, focc: function (e) {
                //this.$refs.test.$el.children[1].children[0].focus();

            },
            goToGatewayList(data) {
                console.log(JSON.stringify(data.item));
                // this.$router.push({name: 'AgencyUsers', params: {data: data.item}});
            },
            createModal() {
                console.log("createModal");
                this.modalColor = 'primary';//success(update),primary(create),danger,info,dark
                this.darkModal = true;
                this.modalValue = {file:null}
                let el = this.$refs.version.$el
                setTimeout(function () {
                    el.getElementsByTagName("input")[0].focus()
                }, 200);

            }, createMappingModal() {
                console.log("createMappingModal");
                this.modalColor = 'primary';//success(update),primary(create),danger,info,dark
                this.mappingModal = true;
                this.mappingValue = {}
            }, updateModal(data) {
                this.modalColor = 'success';//success(update),primary(create),danger,info,dark
                this.darkModal = true;
                this.modalValue = Object.assign({}, data.item);
            }, save() {
                delete this.modalValue["__ob__"];
                console.log("save", this.modalValue);
                var formData = new FormData();
                formData.append("firmwareVersion", this.modalValue.firmwareVersion);
                formData.append("memo", this.modalValue.memo);
                formData.append("isLatest", this.modalValue.isLatest == true);
                // formData.append("file",this.$refs.file.files[0]);
                if (this.modalValue.file != undefined) {
                    formData.append("file",  this.modalValue.file);
                }
                formData.append("updateReservTime", `${this.modalValue["updateReservDate"]} ${this.modalValue["updateReservTime"]}`);
                console.log("modalValue", formData);
                this.axios.post('/api/saveGatewayFirmware', formData
                    //this.axios.post('/api/gw/firmware/saveOrUpdate', formData
                    , {
                        //headers: {'Content-Type': 'application/json'}
                        headers: {"Content-Type": "multipart/form-data"}
                    }
                ).then(response => {
                    console.log('response', response);
                    this.darkModal = false;
                    this.get();
                }).catch(error => {
                    console.log('error', error);
                    console.log('error', error.response.data);
                }).finally(() => {
                    console.log('finally');
                });

            }, changeCheckBox(data, checkValue) {
                data.item.checked = checkValue;
                this.$set(this.items, data.index, data.item);
            }, allCheck(checkValue) {
                alert(checkValue);
            }
            , delete(data) {
                console.log("delete");
                this.axios.post('/api/device/delete/' + data.id, {
                        headers: {'Content-Type': 'application/json'}
                    }
                ).then(response => {
                    console.log('response', response);
                    this.get();
                }).catch(error => {
                    console.log('error', error);
                    console.log('error', error.response.data);
                }).finally(() => {
                    console.log('finally');
                });
                this.darkModal = false;
            }, get() {
                this.loading = true;
                this.axios.get(`/api/gatewayFirmware`, {
                        headers: {'Content-Type': 'application/json'}
                    }
                ).then(response => {
                    console.log('response', response);
                    this.items = response.data.result;
                }).catch(error => {
                    console.log('error', error);
                }).finally(() => {
                    console.log('finally ');
                    this.loading = false;
                });
            }, activePage(pageNumber) {
                this.paging.currentPage = pageNumber;
                this.get();
            }// バリデーション関連
            , firmwareVersion(val) {
                return this.validation("firmwareVersion");
            }
            , updateReservDate(val) {
                return this.validation("updateReservDate");
            }, updateReservTime(val) {
                return this.validation("updateReservTime");
            }//,file (val) {
            //return this.validation("file");
            //}
            , validation(filedName) {
                // var validation = this.getValidation();
                // // 全項目のチェックしてokならtrue
                // this.isFormValid = !validation.passes();
                // console.log("filedName", filedName, this.modalValue)
                // return !validation.errors.has(filedName);
            },
        // getValidation() {
        //         return new Validator({
        //             firmwareVersion: this.modalValue.firmwareVersion,
        //             updateReservDate: this.modalValue.updateReservDate,
        //             updateReservTime: this.modalValue.updateReservTime,
        //             //        file: this.modalValue.file,
        //         }, {
        //             firmwareVersion: 'required',
        //             updateReservDate: 'required',
        //             updateReservTime: 'required',
        //         });
        //     }
        }
    }
</script>
<style>
    .modal-body .card-body {
        overflow: scroll;
        max-height: 500px;
    }

    /*.card-body {*/
    /*    overflow: scroll;*/
    /*    max-height: 80vh;*/
    /*}*/

</style>
